.goodbye-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.goodbye-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.goodbye-text {
  width: 90%;
  font-size: 24px;
  margin-top: var(--dl-space-space-threeunits);
  text-align: left;
  font-family: "Poppins";
  font-weight: 600;
}
.goodbye-text01 {
  font-size: 32px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1.33;
  text-transform: none;
  text-decoration: none;
}
.goodbye-text02 {
  font-weight: 400;
}
.goodbye-text03 {
  font-weight: 400;
}
.goodbye-text04 {
  font-weight: 400;
}
.goodbye-link {
  font-weight: 400;
  text-decoration: underline;
}
.goodbye-text05 {
  font-weight: 400;
}
.goodbye-text06 {
  font-weight: 400;
}
.goodbye-text07 {
  font-weight: 400;
}
.goodbye-text09 {
  font-weight: 400;
}
.goodbye-text10 {
  font-weight: 400;
}
.goodbye-text11 {
  font-weight: 400;
}
.goodbye-text12 {
  font-weight: 400;
}
.goodbye-text13 {
  font-weight: 400;
}
.goodbye-text15 {
  font-weight: 400;
}
.goodbye-text16 {
  font-weight: 400;
}
.goodbye-text17 {
  font-weight: 400;
}
.goodbye-text18 {
  font-weight: 400;
}
.goodbye-text19 {
  font-weight: 400;
}
.goodbye-text20 {
  font-weight: 400;
}
.goodbye-text22 {
  font-weight: 400;
}
.goodbye-text23 {
  font-weight: 400;
}
.goodbye-text24 {
  font-weight: 400;
}
.goodbye-text25 {
  font-weight: 400;
}
.goodbye-text26 {
  font-weight: 400;
}
.goodbye-text28 {
  font-weight: 400;
}
.goodbye-text29 {
  font-weight: 400;
}
.goodbye-text30 {
  font-weight: 400;
}
.goodbye-text31 {
  font-weight: 400;
}
.goodbye-text32 {
  font-weight: 400;
}
.goodbye-text34 {
  font-weight: 400;
}
.goodbye-text35 {
  font-weight: 400;
}
.goodbye-text36 {
  font-weight: 400;
}
.goodbye-text37 {
  font-weight: 400;
}
.goodbye-text38 {
  font-weight: 400;
}
.goodbye-text40 {
  font-weight: 400;
}
.goodbye-text41 {
  font-weight: 400;
}
.goodbye-text42 {
  font-weight: 400;
}
.goodbye-text43 {
  font-weight: 400;
}
.goodbye-text44 {
  font-weight: 400;
}
.goodbye-text46 {
  font-weight: 400;
}
.goodbye-text47 {
  font-weight: 400;
}
.goodbye-text48 {
  font-weight: 400;
}
.goodbye-text49 {
  font-weight: 400;
}
.goodbye-text50 {
  font-weight: 400;
}
.goodbye-text52 {
  font-weight: 400;
}
.goodbye-text53 {
  font-weight: 400;
}
.goodbye-text54 {
  font-weight: 400;
}
.goodbye-text55 {
  font-weight: 400;
}
.goodbye-text56 {
  font-weight: 400;
}
.goodbye-text58 {
  font-weight: 400;
}
.goodbye-text59 {
  font-weight: 400;
}
.goodbye-text60 {
  font-weight: 400;
}
.goodbye-text61 {
  font-weight: 400;
}
.goodbye-text62 {
  font-weight: 400;
}
.goodbye-text63 {
  font-weight: 400;
}
.goodbye-text64 {
  font-weight: 400;
}
.goodbye-text65 {
  font-weight: 600;
}
