.heist-steal-a-minute-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-start;
}
.heist-steal-a-minute-container1 {
  flex: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-sixunits);
  align-items: center;
  justify-content: center;
}
.heist-steal-a-minute-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.heist-steal-a-minute-text {
  width: 90%;
  font-size: 64px;
  text-align: center;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.heist-steal-a-minute-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: 88px;
  display: flex;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  justify-content: center;
}
.heist-steal-a-minute-text1 {
  text-align: center;
}
.heist-steal-a-minute-container4 {
  flex: 0 0 auto;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.heist-steal-a-minute-text2 {
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-family: Poppins;
  font-weight: 200;
  line-height: 1.33;
  text-transform: none;
  text-decoration: none;
}
.heist-steal-a-minute-container5 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
}
.heist-steal-a-minute-iframe {
  width: 350px;
  height: 350px;
}
@media(max-width: 479px) {
  .heist-steal-a-minute-text {
    font-size: 48px;
  }
}
