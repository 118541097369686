.heist-help-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.heist-help-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.heist-help-text {
  width: 90%;
  font-size: 24px;
  margin-top: var(--dl-space-space-threeunits);
  text-align: left;
  font-family: "Poppins";
  font-weight: 600;
}
.heist-help-text01 {
  font-size: 32px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1.33;
  text-transform: none;
  text-decoration: none;
}
.heist-help-text02 {
  font-weight: 400;
}
.heist-help-text03 {
  font-weight: 400;
}
.heist-help-text04 {
  font-weight: 400;
}
.heist-help-link {
  font-weight: 400;
  text-decoration: underline;
}
.heist-help-text05 {
  font-weight: 400;
}
.heist-help-text06 {
  font-weight: 400;
}
.heist-help-text07 {
  font-weight: 400;
}
.heist-help-text09 {
  font-weight: 400;
}
.heist-help-text10 {
  font-weight: 400;
}
.heist-help-text11 {
  font-weight: 400;
}
.heist-help-text12 {
  font-weight: 400;
}
.heist-help-text13 {
  font-weight: 400;
}
.heist-help-text15 {
  font-weight: 400;
}
.heist-help-text16 {
  font-weight: 400;
}
.heist-help-text17 {
  font-weight: 400;
}
.heist-help-text18 {
  font-weight: 400;
}
.heist-help-text19 {
  font-weight: 400;
}
.heist-help-text20 {
  font-weight: 400;
}
.heist-help-text22 {
  font-weight: 400;
}
.heist-help-text23 {
  font-weight: 400;
}
.heist-help-text24 {
  font-weight: 400;
}
.heist-help-text25 {
  font-weight: 400;
}
.heist-help-text26 {
  font-weight: 400;
}
.heist-help-text28 {
  font-weight: 400;
}
.heist-help-text29 {
  font-weight: 400;
}
.heist-help-text30 {
  font-weight: 400;
}
.heist-help-text31 {
  font-weight: 400;
}
.heist-help-text32 {
  font-weight: 400;
}
.heist-help-text34 {
  font-weight: 400;
}
.heist-help-text35 {
  font-weight: 400;
}
.heist-help-text36 {
  font-weight: 400;
}
.heist-help-text37 {
  font-weight: 400;
}
.heist-help-text38 {
  font-weight: 400;
}
.heist-help-text40 {
  font-weight: 400;
}
.heist-help-text41 {
  font-weight: 400;
}
.heist-help-text42 {
  font-weight: 400;
}
.heist-help-text43 {
  font-weight: 400;
}
.heist-help-text44 {
  font-weight: 400;
}
.heist-help-text46 {
  font-weight: 400;
}
.heist-help-text47 {
  font-weight: 400;
}
.heist-help-text48 {
  font-weight: 400;
}
.heist-help-text49 {
  font-weight: 400;
}
.heist-help-text50 {
  font-weight: 400;
}
.heist-help-text52 {
  font-weight: 400;
}
.heist-help-text53 {
  font-weight: 400;
}
.heist-help-text54 {
  font-weight: 400;
}
.heist-help-text55 {
  font-weight: 400;
}
.heist-help-text56 {
  font-weight: 400;
}
.heist-help-text58 {
  font-weight: 400;
}
.heist-help-text59 {
  font-weight: 400;
}
.heist-help-text60 {
  font-weight: 400;
}
.heist-help-text61 {
  font-weight: 400;
}
.heist-help-text62 {
  font-weight: 400;
}
.heist-help-text63 {
  font-weight: 400;
}
.heist-help-text64 {
  font-weight: 400;
}
.heist-help-text65 {
  font-weight: 600;
}
