.eula-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.eula-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #C0B305;
}
.eula-text {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  text-align: center;
  font-family: Poppins;
  font-weight: 800;
  line-height: 1.33;
  text-transform: none;
  text-decoration: none;
}
.eula-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  justify-content: center;
}
.eula-container3 {
  width: 90%;
  height: 13000px;
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-threeunits);
}
.eula-container4 {
  display: contents;
}
@media(max-width: 991px) {
  .eula-container3 {
    height: 14000px;
  }
}
@media(max-width: 767px) {
  .eula-container3 {
    height: 16000px;
  }
}
@media(max-width: 479px) {
  .eula-container3 {
    height: 21500px;
  }
}
