.navbar-interactive-container {
  width: 100%;
  display: flex;
  position: relative;
}
.navbar-interactive-navbar-interactive {
  width: 100%;
  display: flex;
  opacity: 0.9;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: #FFFFFF;
}
.navbar-interactive-navlink {
  display: contents;
}
.navbar-interactive-image {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.navbar-interactive-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.navbar-interactive-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar-interactive-privacy-policy {
  text-decoration: none;
}
.navbar-interactive-burger-menu {
  display: none;
}
.navbar-interactive-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar-interactive-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.navbar-interactive-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-interactive-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar-interactive-image1 {
  width: 200px;
  object-fit: cover;
}
.navbar-interactive-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-interactive-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar-interactive-privacy-policy1 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.navbar-interactive-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.navbar-interactive-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

@media(max-width: 767px) {
  .navbar-interactive-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .navbar-interactive-desktop-menu {
    display: none;
  }
  .navbar-interactive-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar-interactive-privacy-policy1 {
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .navbar-interactive-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .navbar-interactive-mobile-menu {
    padding: 16px;
  }
}
